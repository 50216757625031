import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline is-justify-content-center">
    {gridItems.map((area) => (
      <div key={area.title} className="column is-4">
        <div
          class="card"
          style={{
            boxShadow:
              "0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02), inset 0 0 1px 1px #1a1a1a",
          }}
        >
          <div className="card-image">
            <figure class="image is-fullwidth">
              <PreviewCompatibleImage imageInfo={area} alt={area.alt} />
            </figure>
          </div>
          <div className="card-content has-background-primary">
            <div>
              <div className=" has-text-centered">
                <h3 className="has-text-black title is-4">{area.title}</h3>
              </div>
            </div>
            {/* <div className="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              nec iaculis mauris.
            </div> */}
          </div>
        </div>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
