import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import TeamMembers from "../components/TeamMembers";
import Practices from "../components/Practices";
import FullWidthImage from "../components/FullWidthImage";
import ButtonLink from "../components/ButtonLink";
import { SEO } from "../components/SEO";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  heading,
  subheading,
  mainpitch,
  staff,
  practice,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage img={heroImage} title={heading} subheading={subheading} />
      <section id="intro" className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content has-text-centered">
                  <div className="is-justify-content-center is-size-4">
                    <h2 className="title is-size-3-mobile is-size-2-tablet">
                      {mainpitch.title}
                    </h2>
                  </div>
                  <div className="tile is-size-6">
                    <p>{mainpitch.description}</p>
                  </div>
                  <div
                    class="is-justify-content-center"
                    style={{ paddingTop: "1.5rem" }}
                  >
                    <ButtonLink to="/contact">Contact Us Now</ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="staff"
        className="section is-medium has-background-black-bis"
      >
        <div className="container">
          <div className="columns">
            <div className="column is-12 has-text-centered">
              <h2 className="title is-size-3-mobile is-size-2-tablet">
                {staff.title}
              </h2>
              <p>{staff.description}</p>
            </div>
          </div>
          <TeamMembers gridItems={staff.members} />
          <div className="columns">
            <div className="column is-12 has-text-centered">
              <Link className="" to="/staff">
                Read More About Our Team
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="practice" className="section is-medium has-background-black">
        <div className="container">
          <div className="columns">
            <div className="column is-12 has-text-centered">
              <h2 className="title is-size-3-mobile is-size-2-tablet">
                {practice.title}
              </h2>
              <p>{practice.description}</p>
            </div>
          </div>
          <Practices gridItems={practice.areas} />
          <div className="columns">
            <div className="column is-12 has-text-centered">
              <Link className="" to="/practice-areas">
                Learn More About Our Practices
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  staff: PropTypes.shape({
    members: PropTypes.array,
  }),
  practice: PropTypes.shape({
    areas: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        staff={frontmatter.staff}
        practice={frontmatter.practice}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        staff {
          title
          description
          members {
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
            alt
            name
            position
          }
        }
        practice {
          title
          description
          areas {
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
            alt
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => (
  <SEO
    title={data.markdownRemark.frontmatter.title}
    description={data.markdownRemark.frontmatter.description}
  />
);
